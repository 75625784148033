// import './App.css';
import Todolist from "./Compoments/Todolist";
import Donelist from "./Compoments/Donelist";
import { EditTodo } from "./Compoments/EditTodo";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import data from "./data";

import { useState } from "react";

function App(props) {
  const [todos, setTodos] = useState(data);
  const [EditModal, toggleEditModal] = useState(false);
  const [toEditTodo, setToEditTodo] = useState();

  const handleAddTodo = (title, content) => {
    setTodos((preTodos) => {
      let add = {
        id: preTodos[preTodos.length - 1].id + 1,
        title: title,
        content: content,
        complete: false,
      };
      return [...preTodos, add];
    });
    // console.log(todos)
  };

  const handleToggle = (id) => {
    setTodos((preTodos) =>
      preTodos.map((preTodo) => {
        if (preTodo.id === id) {
          if (preTodo.complete) {
            return {
              ...preTodo,
              complete: false,
            };
          } else {
            return {
              ...preTodo,
              complete: true,
            };
          }
        }
        return preTodo;
      })
    );
  };

  const handleDelete = (id) => {
    setTodos((preTodos) =>
      preTodos.map((preTodo) => {
        if (preTodo.id === id) {
          preTodo.complete = "remove";
        }
        return preTodo;
      })
    );
  };

  const handleEdit = (id, title, content) => {
    setTodos((preTodos) =>
      preTodos.map((preTodo) => {
        if (preTodo.id === id) {
          preTodo.title = title;
          preTodo.content = content;
        }
        return preTodo;
      })
    );
  };

  const handleToggleEditModal = (status, id = null) => {
    setToEditTodo(id);
    toggleEditModal(!EditModal);
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Todolist
                data={todos}
                onToggle={handleToggle}
                onDelete={handleDelete}
                onAdd={handleAddTodo}
                onEdit={handleEdit}
                handleToggleEditModal={handleToggleEditModal}
              />
            }
          />
          <Route
            path="/Done"
            element={
              <Donelist
                data={todos}
                onToggle={handleToggle}
                onDelete={handleDelete}
                onAdd={handleAddTodo}
                onEdit={handleEdit}
                handleToggleEditModal={handleToggleEditModal}
              />
            }
          />
        </Routes>
        {EditModal ? (
          <EditTodo
            showModal={EditModal}
            onToggleEditModal={handleToggleEditModal}
            onEdit={handleEdit}
            data={todos.filter((pre) => {
              return pre.id === toEditTodo;
            })}
          />
        ) : null}
      </BrowserRouter>
    </div>
  );
}

export default App;
