import { React } from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  const { onToggleModal } = props;
  const handleAddTodo = () => {
    onToggleModal(true);
  };

  return (
    <div className="MyNav flex-column flex-lg-row">
      <h2>C110106107 劉育呈</h2>
      <div>
        <button
          className="btn btn-Light"
          style={{ backgroundColor: "#8AE6BB" }}
          onClick={handleAddTodo}
        >
          新增待辦
        </button>
        <Link
          to="/Done"
          className="btn btn-Light"
          style={{ backgroundColor: "#BFC0FF", marginLeft: "2vw" }}
        >
          已完成
        </Link>
        <Link
          to="/"
          className="btn btn-Light"
          style={{ backgroundColor: "#FFFAB6", marginLeft: "2vw" }}
        >
          未完成
        </Link>
      </div>
    </div>
  );
}
