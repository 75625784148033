import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faCircleCheck,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

export default function Todo(props) {
  const [data] = useState(props.data);

  const handleToggle = (e) => {
    const id = props.data.id;
    const { onToggle } = props;
    onToggle(id);
    console.log("this is toggle");
  };

  const handleDelete = (e) => {
    const id = props.data.id;
    const { onDelete } = props;
    console.log("this is delete");
    onDelete(id);
  };

  const handleEditClick = (e) => {
    const { id } = data;
    const { onEditClick } = props;
    onEditClick(true, id);
    // console.log(props)
  };
  return (
    <div
      className="col-12 col-lg-3 mt-3"
      style={{ minHeight: "20vh", borderRadius: "100px" }}
    >
      <div
        className="h-100 px-2"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          backgroundColor: "#eee",
        }}
      >
        <span style={{ fontSize: "2vw", fontWeight: "1000" }}>
          {" "}
          {props.data.title}
        </span>
        <div
          style={{
            fontSize: "1vw",
            fontWeight: "bolder",
            minHeight: "15vh",
            maxHeight: "10vh",
            overflow: "hidden",
          }}
        >
          {props.data.content}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "20px",
          }}
        >
          {!props.data.complete ? (
            <FontAwesomeIcon icon={faCircleCheck} onClick={handleToggle} />
          ) : (
            <FontAwesomeIcon icon={faBackward} onClick={handleToggle} />
          )}
          <div>
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ marginRight: "4px", color: "red", fontSize: "20px" }}
              onClick={handleDelete}
            />
            <FontAwesomeIcon
              icon={faPen}
              style={{ color: "blue", fontSize: "20px" }}
              onClick={handleEditClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
