import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Link } from "react-router-dom";

function AddTodo(props) {
  const [show] = useState(props.showModal);
  const [Todo, AddTodos] = useState({
    title: "",
    content: "",
  });
  const handleClose = () => {
    const { onToggleModal } = props;
    onToggleModal(false);
  };
  const handleAdd = (e) => {
    e.preventDefault();
    const { title, content } = Todo;
    const { onAdd } = props;
    onAdd(title, content);
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    AddTodos({
      ...Todo,
      [name]: value,
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>新增待辦事項</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAdd}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>待辦事項</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>待辦事項內容</Form.Label>
              <Form.Control
                type="textarea"
                rows={3}
                name="content"
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="success" type="submit">
              新增
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="secondary" onClick={handleClose}>
              關閉
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export { AddTodo };
