import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Link } from "react-router-dom";

function EditTodo(props) {
  const [show] = useState(props.showModal);
  const { id, title, content } = props.data[0];
  console.log(props);
  const [Todo, AddTodos] = useState({
    id: id,
    title: title,
    content: content,
  });
  const handleClose = () => {
    const { onToggleEditModal } = props;
    onToggleEditModal(false);
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const { onEdit } = props;
    const { id, title, content } = Todo;

    onEdit(id, title, content);
    handleClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    AddTodos({
      ...Todo,
      [name]: value,
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>編輯待辦事項</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEdit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>待辦事項</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                onChange={handleChange}
                value={Todo.title}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>待辦事項內容</Form.Label>
              <Form.Control
                as="textarea"
                type="textarea"
                rows={3}
                name="content"
                onChange={handleChange}
                value={Todo.content}
              />
            </Form.Group>
            <Button variant="success" type="submit">
              修改
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="secondary" onClick={handleClose}>
              關閉
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export { EditTodo };
