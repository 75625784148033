import React, { useState } from "react";
import Todo from "./Todo";
import Navbar from "./Navbar";
import { AddTodo } from "./AddTodo";

export default function Donelist(props) {
  const [Modal, toggleModal] = useState(false);

  const handleToggleModal = (status) => {
    toggleModal(status);
    console.log(status);
  };

  return (
    <div className="wrap-all">
      <div className="container webcon">
        <Navbar onToggleModal={handleToggleModal} />
        <div className="row">
          {props.data.map((data) =>
            data.complete === true ? (
              <Todo
                data={data}
                key={data.id}
                onToggle={props.onToggle}
                onDelete={props.onDelete}
                onToggleModal={handleToggleModal}
                onEdit={props.onEdit}
                onEditClick={props.handleToggleEditModal}
              />
            ) : null
          )}
        </div>
      </div>
      {Modal ? (
        <AddTodo
          showModal={Modal}
          onToggleModal={handleToggleModal}
          onAdd={props.onAdd}
        />
      ) : null}
    </div>
  );
}
